<template>
  <MainLoader v-if="loadLoader()" />
  <router-view></router-view>
</template>

<script setup lang="ts">
import { onMounted } from "vue";
import { useUserStore, useThemeStore, useAuthStore } from "@/stores";
import MainLoader from "@/layouts/loader/MainLoader.vue";
import { showLoader } from "./utils/utils";
import { useRoute } from "vue-router";
import { useAppInitiate } from "@/composables/useAppInitiate.ts";
import { initProductFruits } from "./services/productFruits.ts";
const user = useUserStore();
const theme = useThemeStore();
const auth = useAuthStore();
const route = useRoute();
const { getHostCompanyData } = useAppInitiate();
const loadLoader = () => {
  return route.name !== "invitation_link_verification" ?? false;
};

onMounted(async () => {
  /*
   * Initial screen size...
   * Screen size changes catch up here...
   * */
  theme.changeScreenSize(window.innerWidth);
  window.onresize = () => {
    setTimeout(() => {
      theme.changeScreenSize(window.innerWidth);
    }, 100);
  };
  if (window.location.pathname.includes("/appless-")) {
    return false;
  }
  user
    .fetchingUserInfo()
    .then(() => {
      user.getCompanyUserDetails().finally(async () => {
        showLoader(false);
        await getHostCompanyData();
        user.$state.isCompanyApiExecuting = false;
      });
    })
    .finally(() => {
      user.$state.shouldAuthenticationBegin = true;
      auth.$state.verifyAPIExecuting = false;
      initProductFruits();
    });
});
</script>
