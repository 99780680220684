import { useUserStore } from "@/stores";
import { productFruits } from "product-fruits";
import { ROLE } from "../enums/commonEnum";

/**
 * Initializes the Product Fruits widget with user-specific data.
 * This function retrieves user information from the store and passes it to the Product Fruits widget.
 * @function
 * @name initProductFruits
 * @returns {void} Initializes Product Fruits with the current user's information.
 */
export const initProductFruits = (
  language = localStorage.getItem("locale") as string
) => {
  // Get the user store instance
  const user = useUserStore();

  // Extract user information attributes from the store
  const userInfoAtrributes = user.getUserInfo.attributes;

  const role = user.getRoles.includes(ROLE.MASTER_ADMIN)
    ? "Master Admin"
    : "Admin";

  // Prepare user data object to pass to Product Fruits
  const productFruitsUserData = {
    username: userInfoAtrributes["email"], // User's email as username
    email: userInfoAtrributes["email"], // User's email
    firstname: userInfoAtrributes["custom:first_name"], // User's first name
    lastname: userInfoAtrributes["custom:last_name"], // User's last name
    role: role, // User's assigned role
  };

  // Initialize the Product Fruits widget with workspace ID, language, user data, and options
  productFruits.init(
    import.meta.env.VITE_APP_PRODUCTFRUITS_WORKSPACE_ID, // Product Fruits workspace ID from environment
    language, // Default language (English)
    productFruitsUserData // User-specific data
  );
};
